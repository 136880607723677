import './App.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/index';
import { BroadcastChannel } from 'broadcast-channel';
import { ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { logoutRequest, changePermissions, setLenguage, getBackOfficeOrganization } from './actions/index';
import { useTranslation } from 'react-i18next';
import { env } from './config/environment';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const colors =
  window.location.origin === env.certikaURL
    ? { primary: '#00b9bc', secondary: '#008A91', tertiary: '#E9FFFF', background: '#FFF', footer: '#E3E4E6' }
    : window.location.origin === env.mincienciasURL && {
        primary: '#3366cc',
        secondary: '#9ac32a',
        tertiary: '#d1d7e3',
        background: '#FFF',
      };

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      main: colors.tertiary,
    },
    background: {
      main: colors.background,
    },
    footer: {
      main: colors.footer,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
        margin: '0.5em',
        borderRadius: '50px',
        textTransform: 'none',
        fontSize: '16px',
        padding: '7px 40px',
      },
      outlinedPrimary: {
        margin: '0.5em',
        borderRadius: '50px',
        textTransform: 'none',
        fontSize: '16px',
        padding: '7px 40px',
      },
    },
    MuiDivider: {
      root: {
        margin: '10px 0px',
      },
    },
    MuiTextField: {
      root: {
        '& .MuiInputBase-root': {
          borderRadius: '16px',
        },
      },
    },
    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px',
        },
      },
    },
  },
});

function App(props) {
  const { changePermissions, logoutRequest, setLenguage, getBackOfficeOrganization } = props;
  const logoutChannel = new BroadcastChannel('logout');
  const lenguageChannel = new BroadcastChannel('lenguage');
  const { i18n } = useTranslation();
  const title = document.getElementById('title');
  const favicon = document.getElementById('favicon');

  useEffect(() => {
    if (window.self !== window.top) {
      // La app está incrustada en un iframe no autorizado
      window.top.location = window.self.location;
    }
  }, []);

  const logoutAllTabsEventListener = () => {
    logoutChannel.onmessage = (event) => {
      if (event === 'CerrarSesion') {
        logoutRequest();
        changePermissions();
        window.location.reload();
        logoutChannel.close();
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 150);
      }
    };
    lenguageChannel.onmessage = (event) => {
      i18n.changeLanguage(event);
      setLenguage(event);
      lenguageChannel.close();
    };
  };

  const backoffice = () => {
    // Cambiar el valor de la variable CSS
    document.documentElement.style.setProperty('--color-primary', colors.primary);
    document.documentElement.style.setProperty('--color-secondary', colors.secondary);
    document.documentElement.style.setProperty('--color-tertiary', colors.tertiary);

    if (window.location.origin === env.certikaURL) {
      getBackOfficeOrganization(1);
      document.title = 'Certika';
      favicon.href = env.faviconCertika;
      return (
        <HelmetProvider>
          <div>
            <Helmet prioritizeSeoTags>
              <title>Certika</title>
              <link rel="icon" href={env.faviconCertika} />
              <link rel="apple-touch-icon" href={env.faviconCertika} />
              <meta property="og:title" content="Certika" />
              <meta
                property="og:description"
                content="Certika es la plataforma web que permite certificar cualquier documento digital de valor, bajo tecnología blockchain, garantizando la imposibilidad de ser falsificado o alterado en el futuro."
              />
              <script
                type="text/javascript"
                async="true"
                src="https://api.at.getsocial.io/get/v1/27d2db39/gs_async.js"
              />
            </Helmet>
          </div>
        </HelmetProvider>
      );
    } else if (window.location.origin === env.mincienciasURL) {
      getBackOfficeOrganization(env.idMinciencias);
      document.title = 'Minciencias | Ministerio de Ciencia Tecnología e Innovación';
      favicon.href = env.faviconMinciencias;
      return (
        <HelmetProvider>
          <div>
            <Helmet prioritizeSeoTags>
              <title>Minciencias | Ministerio de Ciencia Tecnología e Innovación</title>
              <link rel="icon" href={env.faviconMinciencias} />
              <link rel="apple-touch-icon" href={env.faviconMinciencias} />
              <meta property="og:title" content="Minciencias | Ministerio de Ciencia Tecnología e Innovación" />
              <meta property="og:description" content="Ministerio de Ciencia Tecnología e Innovación" />
              <script
                type="text/javascript"
                async="true"
                src="https://api.at.getsocial.io/get/v1/5b7ee5e6/gs_async.js"
              />
            </Helmet>
          </div>
        </HelmetProvider>
      );
    }
  };

  return (
    <>
      {backoffice()}
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          {logoutAllTabsEventListener()}
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

const mapDispatchToProps = {
  logoutRequest,
  changePermissions,
  setLenguage,
  getBackOfficeOrganization,
};

export default connect(null, mapDispatchToProps)(App);
